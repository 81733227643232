import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CircularProgressX from '../components/CircularProgressX';
import parse from 'html-react-parser';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = { articles: [], loading: true };
    }

    componentDidMount() {
        this.populateArticles();
    }

    async populateArticles() {
        const response = await fetch('api/articles');
        // Check response.statusText (NotFound)
        const data = await response.json();
        this.setState({ articles: data, loading: false });
    }

    render() {
        let contents = this.state.loading
            ? <CircularProgressX />
            : Home.renderArticlesList(this.state.articles);

        return (<div>{contents}</div>);
    }

    static renderArticlesList(articles) {
        return (
            <React.Fragment>
                <Typography variant="h5">South African Tournament Water Ski</Typography>
                {articles.map(article =>
                    <Card key={article.id} className='mx-auto my-3' sx={{ maxWidth: 600 }}>
                        <CardActionArea component={Link} to={"articles/" + article.id}>
                            <CardMedia sx={{ height: 300 }} image={article.imageHref} />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div" sx={{ textDecoration: 'none' }}>
                                    {article.title}
                                </Typography>
                                <Typography gutterBottom variant="subtitle2" component="div">
                                    {article.dateText}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="div">
                                    {parse(article.previewHtml)}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary" component={Link} to={"articles/" + article.id}>Read More</Button>
                        </CardActions>
                    </Card>
                )}
            </React.Fragment>
        )
    }
}
