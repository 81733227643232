import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && ( <Box>{children}</Box> )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TabsX(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {                
        setValue(newValue);        
        if (props.onTabChange) {
            var tabId = event.currentTarget.getAttribute("data-id")
            var tab = props.tabs.find(tab => { return String(tab.id) === String(tabId) })
            props.onTabChange(tab)
        }
    };                    

    var tabIndex = 0;
    var tabPanelIndex = 0;
    return (       
        <React.Fragment>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="tabs">
                    {props.tabs.map(tab =>
                        <Tab data-id={tab.id} label={tab.label} sx={{ fontSize: '16px', textTransform: 'none', '&.Mui-selected': { outline: 'none' } }} {...a11yProps(tabIndex++)} />
                    )}
                </Tabs>
            </Box>
            {props.tabs.map(tab =>
                <TabPanel value={value} index={tabPanelIndex++} tab={tab}>
                    {tab.render()}
                </TabPanel>                
            )}            
        </React.Fragment>
    );        
}
