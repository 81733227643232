import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import Box from '@mui/material/Box';
import { Facebook } from './Facebook';
import { Instagram } from './Instagram';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container className='d-flex align-items-end'>
                        <NavbarBrand tag={Link} to="/"><img alt='' src='images/SATW.png' width='256' height='76' /></NavbarBrand>
                        
                        <Collapse className="d-flex justify-content-end" isOpen={!this.state.collapsed} navbar>                            
                            <ul className="navbar-nav flex-grow d-sm-inline-flex flex-sm-row">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/events">Events</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/Ranking">Ranking</NavLink>
                                </NavItem>                                
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/contact">Contact</NavLink>
                                </NavItem>                               
                            </ul>
                            <Box className="d-flex">
                                <Instagram />
                                <Facebook />
                            </Box>
                        </Collapse>

                    </Container>
                </Navbar>
            </header>
        );
    }
}
/*
 <NavItem>
    <NavLink tag={Link} className="text-dark" to="/NavMenuTabsTest">NavMenuTabsTest</NavLink>
</NavItem>
<NavItem>
    <a href="http://www.iwsf.com/rules/2023/World%20Waterski%20Rules%202023_V1.pdf" target="_blank">IWWF Rules 2023</a>
</NavItem>
 */ 

/*
 *  render () {
    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                <Container className='align-items-end'>
                    <NavbarBrand tag={Link} to="/">
                        <img alt='' src='images/SATW.png' width='256' height='76' />
                    </NavbarBrand>
                    <Box>
                        <Box className="d-flex justify-content-end">
                            <Instagram />
                            <Facebook />
                        </Box>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow d-sm-inline-flex flex-sm-row">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/events">Events</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/Ranking">Ranking</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/contact">Contact</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Box>
                </Container>
            </Navbar>
        </header>
    );
  }
}

*/

/*
 * <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/eventstabs">EventsTabs</NavLink>
                            </NavItem>
                            */

/*<NavItem>
                                <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
                            </NavItem>
                             <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/articles">Article</NavLink>
                            </NavItem>
                            */

/*<header>
    <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
        <Container className='align-items-end'>
            <NavbarBrand tag={Link} to="/"><img alt='' src='images/SATW.png' width='256' height='76' /></NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                <ul className="navbar-nav flex-grow">
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/events">Events</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/articles">Article</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/tabs">Tabs</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/Ranking">Ranking</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/contact">Contact</NavLink>
                    </NavItem>
                </ul>              
            </Collapse>
        </Container>
    </Navbar>
</header>*/

/*
 * <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container className='align-items-end'>
            <NavbarBrand tag={Link} to="/"><img alt='' src='images/SATW.png' width='256' height='76'/></NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="flex-column align-items-end" isOpen={!this.state.collapsed} navbar>
                        <Box className="d-flex">
                            <Instagram />
                            <Facebook />
                        </Box>
                        <ul className="navbar-nav flex-grow d-sm-inline-flex flex-sm-row">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/events">Events</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/articles">Article</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/tabs">Tabs</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/Ranking">Ranking</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/contact">Contact</NavLink>
                </NavItem>
              </ul>
                    </Collapse>

          </Container>
        </Navbar>
      </header>
      */
