import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CircularProgressX from '../components/CircularProgressX';

export class Contact extends Component {
    static displayName = Contact.name;

    constructor(props) {
        super(props);
        this.state = { roles: [], loading: true };
    }

    componentDidMount() {
        this.populateRolesAndContacts();
    }

    async populateRolesAndContacts() {
        const response = await fetch('api/contacts');
        const data = await response.json();
        this.setState({ roles: data, loading: false });
    }

    render() {
        let contents = this.state.loading
            ? <CircularProgressX />
            : Contact.renderRolesAndContacts(this.state.roles);

        return ( <div>{contents}</div> );
    }

    static renderRolesAndContacts(roles) {
        let key = 0;
        return (
            <React.Fragment>
                <Typography variant="h5">Contact - 2024 Committee</Typography>
                <Grid container spacing={3} >
                    {roles.map(role =>     
                        <Grid item>                            
                            <Card key={key++} elevation={3} className="mx-auto my-3" sx={{ maxWidth: 400 }}>                        
                                <CardContent>
                                    <Typography className="mb-2" variant="h5" component="div">
                                        {role.roleName}
                                    </Typography>
                                    <Divider />
                                    {role.contacts.map(contact =>                                
                                        <React.Fragment key={key++}>
                                            <CardMedia component="img" image={contact.imageHref} />
                                            <Typography variant="h6" component="div">
                                                {contact.name}
                                            </Typography>
                                            {Contact.renderEmail(contact)}
                                            {Contact.renderPhone(contact)}
                                        </React.Fragment>
                                    )}
                                </CardContent>
                            </Card>                            
                        </Grid>)}
                    </Grid>
            </React.Fragment>
        )
    }   

    static renderEmail(contact) {
        if (contact.email)            
            return (
                <Typography variant="body2" component="p">
                    email: <a href={"mailto:" + contact.email}>{contact.email}</a>
                </Typography>          
            )
        return null;
    }

    static renderPhone(contact) {
        if (contact.phone)
            return (
                <Typography variant="body2" component="p" className="mb-2">
                    Phone: <a href={"tel:" + contact.phone.replace(/ /g, '')}>{contact.phone}</a>
                </Typography>   
            )
        return null;
    }    
}
