import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./pages/Home";
import { Events } from './pages/Events';
import { EventResults } from './pages/EventResults';
import { Article } from './pages/Article';
import { Ranking } from './pages/Ranking';
import { Contact } from './pages/Contact';
import { EventGallery } from './pages/EventGallery'; 
import { NavMenuTabsTest } from './pages/NavMenuTabsTest'; 

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/events',
    element: <Events />
  },
  {
    path: '/events/:event_id/results',
    element: <EventResults />
  },
  {
    path: '/events/:event_id/gallery',
    element: <EventGallery />
  },
  {
    path: '/articles/:article_id',
    element: <Article />
  },
  {
    path: '/ranking',
    element: <Ranking />
  },
  {
    path: '/contact',
    element: <Contact />
  },
  {
    path: '/NavMenuTabsTest',
    element: <NavMenuTabsTest />
  },
  {
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
  }
];

export default AppRoutes;
