import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface LinkTabProps {
    label?: string;
    href?: string;
}

export default class NavTabs extends Component {
    static displayName = NavTabs.name;

    constructor(props) {
        super(props);
        this.state = { activePage: 0 };
    }        

    handleChange(event: React.SyntheticEvent, newActivePage: number) {
        if (event.type !== 'click' || (event.type === 'click' &&
           this.samePageLinkNavigation(event))) {
            this.setState({ activePage: newActivePage });
            alert("Change");
        }        
    };  

    samePageLinkNavigation(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        if (event.defaultPrevented ||
            event.button !== 0 || // ignore everything but left-click
            event.metaKey ||
            event.ctrlKey ||
            event.altKey ||
            event.shiftKey) {
            return false;
        }
        return true;
    }

    LinkTab(props: LinkTabProps) {
        return (
            <Tab
                component="a"
                onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                    // Routing libraries handle this, you can remove the onClick handle when using them.
                    if (this.samePageLinkNavigation(event)) {
                        event.preventDefault();
                    }
                }}
                {...props}
            />
        );
    }      

    render() {
        return (
            <Box sx={{ width: '100%' }}>
                <Tabs value={this.state.activePage} onChange={this.handleChange}>
                    <this.LinkTab className="text-dark" sx={{ textTransform: "none" }} label="Page One" href="/Home" />
                    <this.LinkTab className="text-dark" sx={{ textTransform: "none" }} label="Page Two" href="/Events" />
                    <this.LinkTab className="text-dark" sx={{ textTransform: "none" }} label="Page Three" href="/Contact" />
                </Tabs>
            </Box>
        );
    }
}
