import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import parse from 'html-react-parser';
import CircularProgressX from '../components/CircularProgressX';

export class Article extends Component {
    static displayName = Article.title;

    constructor(props) {
        super(props);
        this.state = { article: null, contentHtml: null, loading: true };
    }

    componentDidMount() {        
        this.populateArticleAndContent();
    }

    async populateArticleAndContent() {
        const [articleResponse, contentHtmlResponse] = await Promise.all([
            fetch('api' + window.location.pathname),
            fetch('api' + window.location.pathname + '/content')
        ]);
        const article = await articleResponse.json();
        const contentHtml = await contentHtmlResponse.json();
        this.setState({ article: article, contentHtml: contentHtml, loading: false });
    }

    render() {
        let contents = this.state.loading
            ? <CircularProgressX />
            : Article.renderArticleAndContents(this.state.article, this.state.contentHtml);

        return ( <div>{contents}</div> );
    }

    static renderArticleAndContents(article, contentHtml) {
        return (            
            <Card className='mx-auto m-3' sx={{ width: '100%' }}>
                <CardMedia height="400" sx={{ height: '400px' }} component="img" image={article.imageHref} />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {article.title}
                    </Typography>
                    <Typography gutterBottom variant="subtitle2" component="div">
                        {article.dateText}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="div">
                        {parse(contentHtml)}
                    </Typography>
                </CardContent>
            </Card>             
        );
    }   
}
