import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export default function CircularProgressX() {
    return (
        <Box className="d-flex justify-content-center py-5">
            <CircularProgress disableShrink />
        </Box>
    );
}
