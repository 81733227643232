import React, { Component } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import TabsX from '../components/Tabs';
import CircularProgressX from '../components/CircularProgressX';

export class Ranking extends Component {
    static displayName = Ranking.name;

    constructor(props) {
        super(props);      
        this.state = { rankings: null, loading: true };
    } 

    componentDidMount() {
        this.populateRankings();
    }

    async populateRankings() {
        const response = await fetch('api/rankings');
        const data = await response.json();
        this.setState({ rankings: data, loading: false });
    }

    render() {
        let contents = this.state.loading
            ? <CircularProgressX />
            : Ranking.renderRankings(this.state.rankings);

        return ( <div>{contents}</div> );
    }

    static renderRankings(rankings) {
        const tabs = [
            { id: 0, label: "Slalom", render: function () { return Ranking.renderDisciplineRankingsTable(rankings.slalom) } },
            { id: 1, label: "Trick", render: function () { return Ranking.renderDisciplineRankingsTable(rankings.trick) } },
            { id: 2, label: "Jump", render: function () { return Ranking.renderDisciplineRankingsTable(rankings.jump) } },
        ];
        return (
            <React.Fragment>
                <Typography variant="h5">Ranking</Typography>
                <Typography variant="subtitle1">Unofficial ranking based on best tournament score</Typography>
                <TabsX tabs={tabs} />
            </React.Fragment>
        );
    }

    static renderDisciplineRankingsTable(rankings) {
        return (
            <React.Fragment>
                {rankings.map(ranking =>
                    <Paper className='my-4'>
                        <Typography variant="h6">{ranking.category}</Typography >
                        <TableContainer>
                            <Table stickyHeader className='table-striped' size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="px-1 py-2" sx={{ fontWeight: 'bold', minWidth: 40 }}>Rank</TableCell>
                                        <TableCell className="px-1 py-2" sx={{ fontWeight: 'bold', minWidth: 130 }}>Name</TableCell>
                                        <TableCell className="px-1 py-2" sx={{ fontWeight: 'bold', minWidth: 68 }}>Country</TableCell>                                        
                                        <TableCell className="px-1 py-2" sx={{ fontWeight: 'bold', minWidth: 90 }}>Score</TableCell>                                  
                                        <TableCell className="px-1 py-2" sx={{ fontWeight: 'bold', minWidth: 336 }}>Event(s)</TableCell>                                  
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ranking.ranking.map(athleteRank =>
                                        <TableRow key={athleteRank.rank}>
                                            <TableCell className="px-1 py-2">{athleteRank.rank}</TableCell>
                                            <TableCell className="px-1 py-2">{athleteRank.name}</TableCell>
                                            <TableCell className="px-1 py-2">{athleteRank.country + "  "}<img alt='' src={"images/country/" + athleteRank.countryAlpha2 + ".png"} width="24" height="24"></img></TableCell>                                            
                                            <TableCell className="px-1 py-2">{athleteRank.score}</TableCell> 
                                            <TableCell className="px-1 py-2">{athleteRank.events.join(", ")}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                )}
            </React.Fragment>
        );
    }   
}