import React, { Component } from 'react';
import NavMenuTabs from '../components/NavMenuTabs';
import NavTabs from '../components/NavTabs';

export class NavMenuTabsTest extends Component {
    static displayName = NavMenuTabsTest.name;

    constructor(props) {
        super(props);
        this.state = { articles: [], loading: true };
    }

    componentDidMount() {
        this.populateArticles();
    }

    async populateArticles() {
        const response = await fetch('api/articles');
        // Check response.statusText (NotFound)
        const data = await response.json();
        this.setState({ articles: data, loading: false });
    }

    render() {
        return (
            <div>
                <NavMenuTabs />                                
                <NavTabs />                                
            </div>
        );
    }    
}
