import React, { Component } from 'react';
import CircularProgressX from '../components/CircularProgressX';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import TabsX from '../components/Tabs';

export class EventResults extends Component {
    static displayName = EventResults.name;

    constructor(props) {
        super(props);
        this.state = { event: null, results: [], loading: true };
    }

    componentDidMount() {
        this.populateEventAndResults();
    }

    async populateEventAndResults() {    
        var eventPath = window.location.pathname.substr(0, window.location.pathname.lastIndexOf('/'));
        var resultsPath = window.location.pathname;
        const [eventResponse, resultsResponse] = await Promise.all([
            fetch('api' + eventPath),
            fetch('api' + resultsPath)
        ]);
        const event = await eventResponse.json();
        const results = await resultsResponse.json();
        this.setState({ event: event, results: results, loading: false });
    }

    render() {
        let contents = this.state.loading
            ? <CircularProgressX />
            : EventResults.renderEventResultsTabs(this.state.event, this.state.results);
        return ( <div>{contents}</div> );
    }

    static renderEventResultsTabs(event, results) {
        let tabs = []
        let tabId = 0

        if (results.slalom)
            tabs.push({ id: tabId++, label: "Slalom", render: function () { return EventResults.renderResultsTabPanel(results.slalom) } })
        if (results.trick)
            tabs.push({ id: tabId++, label: "Trick", render: function () { return EventResults.renderResultsTabPanel(results.trick) } })
        if (results.jump)
            tabs.push({ id: tabId++, label: "Jump", render: function () { return EventResults.renderResultsTabPanel(results.jump) } })

        return (
            <React.Fragment>
                <Box className="d-flex justify-content-between">
                    <Typography variant="h5">{event.title + " - Results"}</Typography>
                    {event.hasGallery && <Button startIcon={<PhotoCameraIcon />} sx={{ textTransform: 'none' }} href={"/events/" + event.id + "/gallery"}>
                        Event Gallery
                    </Button>}
                </Box>
                <TabsX tabs={tabs} />
            </React.Fragment>
        )
    }    

    static renderResultsTabPanel(results) {
        return (
            <React.Fragment>
                {results.map(result =>
                    <Paper className='my-4'>
                        <Typography variant="h6">{result.category}</Typography >
                        <TableContainer>
                            <Table stickyHeader className='table-striped' size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="px-1 py-2" sx={{ fontWeight: 'bold', minWidth: 40 }}>Rank</TableCell>
                                        <TableCell className="px-1 py-2" sx={{ fontWeight: 'bold', minWidth: 130 }}>Name</TableCell>
                                        <TableCell className="px-1 py-2" sx={{ fontWeight: 'bold', minWidth: 68 }}>Country</TableCell>
                                        {result.results[0].scores.map(function (score, index) {
                                            return <TableCell className="px-1 py-2" sx={{ fontWeight: 'bold', minWidth: 90 }}>{"Round " + (++index)}</TableCell>;
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {result.results.map(atheleScores =>
                                        <TableRow key={atheleScores.rank}>
                                            <TableCell className="px-1 py-2">{atheleScores.rank}</TableCell>
                                            <TableCell className="px-1 py-2">{atheleScores.name}</TableCell>
                                            <TableCell className="px-1 py-2">{atheleScores.country + "  "}<img alt='' src={"images/country/" + atheleScores.countryAlpha2 + ".png"} width="24" height="24"></img></TableCell>
                                            {atheleScores.scores.map(score =>
                                                <TableCell className="px-1 py-2">{score}</TableCell>
                                            )}
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                )}
            </React.Fragment>
        );
    }    
}