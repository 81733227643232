import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'react-router-dom';

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

export default class NavMenuTabs extends Component {
  static displayName = NavMenuTabs.name;

    constructor(props) {
        super(props);
        this.state = { activePage: 0 };
    }    

    handleChange(event, newValue) {
        this.setState({ activePage: newValue });
        alert("Change");
    };

    render () {
        return (
            <Box sx={{ width: '100%' }}>
                <Tabs value={this.state.value} onChange={this.handleChange} >
                    <LinkTab label="Home" href="/" />
                    <LinkTab label="Events" href="/events" />
                    <LinkTab label="Ranking" href="/ranking" />
                    <LinkTab label="Contact" href="/contact" />
                </Tabs>
            </Box>      
    );
    }
}
