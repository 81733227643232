import React, { Component } from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ImageGallery from 'react-image-gallery';
import CircularProgressX from '../components/CircularProgressX';

import "react-image-gallery/styles/css/image-gallery.css";

export class EventGallery extends Component {
    static displayName = EventGallery.name;

    constructor(props) {
        super(props);        
        this.state = { event: null, galleryImages: [], loading: true };
    }

    componentDidMount() {
        this.populateEventAndGalleryImages();
    }	

    async populateEventAndGalleryImages() {
        var eventPath = window.location.pathname.substr(0, window.location.pathname.lastIndexOf('/'));
        var galleryImagesPath = window.location.pathname;
        const [eventResponse, galleryImagesResponse] = await Promise.all([
            fetch('api' + eventPath),
            fetch('api' + galleryImagesPath)
        ]);
        const event = await eventResponse.json();
        const galleryImages = await galleryImagesResponse.json();
        this.setState({ event: event, galleryImages: galleryImages, loading: false });
    }

    render() {
        let contents = this.state.loading
            ? <CircularProgressX />
            : EventGallery.renderGalleryImages(this.state.event, this.state.galleryImages);

        return ( <div>{contents}</div> );
    }   

    static renderGalleryImages(event, galleryImages) {
        const images = []
        galleryImages.forEach(image => images.push({ original: image, thumbnail: image }));
        return (
            <React.Fragment>
                <Box className="d-flex justify-content-between">
                    <Typography className="pb-2" variant="h5">{event.title}</Typography>
                    {event.hasResults && <Button startIcon={<AssignmentIcon />} sx={{ textTransform: 'none' }} href={"/events/" + event.id + "/results"}>
                        Event Results
                    </Button>}
                </Box>
                <ImageGallery items={images} />
            </React.Fragment>
        )
    }    
}    