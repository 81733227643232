import React, { Component } from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CircularProgressX from '../components/CircularProgressX';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TabsX from '../components/Tabs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import VideocamIcon from '@mui/icons-material/Videocam';

export class Events extends Component {
	static displayName = Events.name;	

	constructor(props) {
		super(props);	
		this.handleTabChange = this.handleTabChange.bind(this);
		this.state = { upcomingEvents: [], upcomingEventsLoading: true, pastEvents: [], pastEventsLoading: true };
	} 

	componentDidMount() {
		this.populateUpcomingEvents();
	}

	async populateUpcomingEvents() {
		if (this.state.upcomingEventsLoading) {
			const response = await fetch('api/events?filter=upcoming');
			const data = await response.json();
			this.setState({
				upcomingEvents: data,
				upcomingEventsLoading: false,
				pastEvents: this.state.pastEvents,
				pastEventsLoading: this.state.pastEventsLoading
			});
		}
	}

	async populatePastEvents() {
		if (this.state.pastEventsLoading) {
			const response = await fetch('api/events?filter=past');
			const data = await response.json();
			this.setState({
				upcomingEvents: this.state.upcomingEvents,
				upcomingEventsLoading: this.state.upcomingEventsLoading,
				pastEvents: data,
				pastEventsLoading: false
			});
		}
	}

	handleTabChange(tab) {
		switch (tab.id) {			
			case 1:
				this.populatePastEvents()
				break
			default:
				this.populateUpcomingEvents()
				break
		}
	}

	render() {
		let upcomingEvents = this.state.upcomingEvents
		let upcomingEventsLoading = this.state.upcomingEventsLoading
		let pastEvents = this.state.pastEvents
		let pastEventsLoading = this.state.pastEventsLoading
		const tabs = [
			{ id: 0, label: "Upcoming", render: function () { return Events.renderTabPanel(upcomingEvents, upcomingEventsLoading, true, false, false, true) } },
			{ id: 1, label: "Past", render: function () { return Events.renderTabPanel(pastEvents, pastEventsLoading, false, true, true, true) } }
		];
		return (
			<React.Fragment>
				<Typography variant="h5">Events</Typography>
				<TabsX tabs={tabs} onTabChange={this.handleTabChange} />
			</React.Fragment>
		)
	}

	static renderTabPanel(events, eventsLoading, showEntryForm, showResults, showGallery, showLiveStreamLinks) {
		let contents = eventsLoading
			? <CircularProgressX />
			: (events.length ? Events.renderEventsTable(events, showEntryForm, showResults, showGallery, showLiveStreamLinks) : Events.renderNoEvents())
		return (
			<React.Fragment>
				{contents}
			</React.Fragment>
		);
	}	

	static renderNoEvents() {
		return (
			<Typography variant="h6" className="mt-3" sx={{ textAlign: 'center' }}>No Events</Typography>
		);
	}

	static renderEventsTable(events, showEntryForm, showResults, showGallery, showLiveStreamLinks) {		
		return (
			<Paper className='my-4'>
				<TableContainer>
					<Table stickyHeader className='table-striped' size='small'>
						<TableHead>
							<TableRow>
								<TableCell sx={{ fontWeight: 'bold', minWidth: 130 }}>Date</TableCell>
								<TableCell sx={{ fontWeight: 'bold', minWidth: 140 }}>Name</TableCell>
								<TableCell sx={{ fontWeight: 'bold', minWidth: 140 }}>Site</TableCell>
								<TableCell sx={{ fontWeight: 'bold', minWidth: 90 }}>Province</TableCell>
								<TableCell sx={{ fontWeight: 'bold', minWidth: 70 }}>Homol</TableCell>
								<TableCell sx={{ fontWeight: 'bold', minWidth: 130 }}>Disciplines</TableCell>
								{showEntryForm && <TableCell sx={{ fontWeight: 'bold', minWidth: 100 }}>Entry Form</TableCell>}
								{showResults && <TableCell sx={{ fontWeight: 'bold', minWidth: 90 }}>Results</TableCell>}
								{showGallery && <TableCell sx={{ fontWeight: 'bold', minWidth: 90 }}>Gallery</TableCell>}
								{showLiveStreamLinks && <TableCell sx={{ fontWeight: 'bold', minWidth: 90 }}>Live Streams</TableCell>}
							</TableRow>
						</TableHead>
						<TableBody>
							{events.map(event =>
								<TableRow key={event.id}>
									<TableCell>{event.dateText}</TableCell>
									<TableCell>{event.name}</TableCell>
									<TableCell><a href={event.siteHref} target="_blank" rel="noopener noreferrer">{event.site}</a></TableCell>
									<TableCell>{event.province}</TableCell>
									<TableCell>{event.homol}</TableCell>
									<TableCell>{event.disciplines.join(", ")}</TableCell>
									{showEntryForm && <TableCell>{event.hasEntryForm && <a href={event.entryFormHref} target="_blank" rel="noopener noreferrer"><AssignmentIcon /></a>}</TableCell>}
									{showResults && <TableCell>{event.hasResults && <a href={"events/" + event.id + "/results"}><AssignmentIcon /></a>}</TableCell>}
									{showGallery && <TableCell>{event.hasGallery && <a href={"events/" + event.id + "/gallery"}><PhotoCameraIcon /></a>}</TableCell>}
									{showLiveStreamLinks && <TableCell>
										{event.hasLiveStreamLinks && event.liveStreamLinks.map(liveStreamLink =>
											<a href={liveStreamLink.href} target="_blank" title={liveStreamLink.title}><VideocamIcon /></a>
										)}
									</TableCell>}									
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		);	
	}	
}
